/**
 * Licensed Materials - Property of HCL Technologies Limited.
 * (C) Copyright HCL Technologies Limited  2023.
 */

import { numberInputContainerSX } from '@/components/blocks/NumberInput/styles/container';
import { numberInputControlsSX } from '@/components/blocks/NumberInput/styles/controls';
import { numberInputInputAdornmentSX } from '@/components/blocks/NumberInput/styles/inputAdornment';
import { ProgressIndicator } from '@/components/blocks/ProgressIndicator';
import { useLocalization } from '@/data/Localization';
import { combineSX } from '@/utils/combineSX';
import { convertMaybeStringToInt } from '@/utils/convertMaybeStringToInt';
import { formatNumberValue } from '@/utils/formatNumberValue';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { ChangeEvent, FC, FocusEvent, useEffect, useRef, useState } from 'react';

type NumberValue = number | null;
type MaybeString = string | number | null;

type Props = Omit<TextFieldProps, 'onChange' | 'value'> & {
	value: MaybeString;
	loading?: boolean;
	min?: number;
	max?: number;
	precision?: number;
	thousandSeparator?: string;
	decimalSeparator?: string;
	showControls?: boolean;
	prefix?: string;
	postfix?: string;
	onChange: (value: NumberValue) => void;
	customValidator?: (value: NumberValue) => boolean;
	disallowEmptyOnBlur?: boolean;
	maxLength?: number;
	disableTextInput?: boolean | false;
	fractionalIncrementQtyLimit?: number | undefined;
};

/**
 * Number input field for incrementing and decrementing numbers, with formatting.
 * @note This component does not support fractional min/max values.
 */
export const NumberInput: FC<Props> = ({
	onChange,
	loading = false,
	precision = 0,
	decimalSeparator = '.',
	thousandSeparator = ',',
	showControls = false,
	value,
	min = 0,
	max,
	prefix,
	postfix,
	sx,
	disallowEmptyOnBlur,
	customValidator,
	maxLength,
	disableTextInput,
	fractionalIncrementQtyLimit,
	...props
}) => {
	const common = useLocalization('Common');
	const [fieldValue, setFieldValue] = useState<MaybeString>(
		() =>
			formatNumberValue({
				value,
				precision,
				decimalSeparator,
				thousandSeparator,
				min,
				max,
				fractionalIncrementQtyLimit,
			}) ?? null
	);
	const [internalValue, setInternalValue] = useState<NumberValue>(() =>
		convertMaybeStringToInt({ input: value, decimalSeparator, thousandSeparator })
	);
	const [externalUpdate, setExternalUpdate] = useState<NumberValue>(() =>
		convertMaybeStringToInt({ input: value, decimalSeparator, thousandSeparator })
	);
	const { disabled } = props;

	const updateValue = (value: string) => {
		const cleanedValue =
			formatNumberValue({
				value,
				precision,
				decimalSeparator,
				thousandSeparator,
				min,
				max,
				fractionalIncrementQtyLimit,
			}) ?? null;
		setFieldValue(cleanedValue);
		setInternalValue(
			convertMaybeStringToInt({
				input: cleanedValue,
				decimalSeparator,
				thousandSeparator,
			})
		);
	};

	const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
		updateValue(e.target.value);
	};

	const step = (amount: number) => () => {
		// Updated for fractional increment sku suppport
		const stepDown = amount < 0 ? true : false;
		isFractionalIncrementSku() && stepDown && (internalValue ?? 0) + amount < 1
			? updateValue('1')
			: updateValue(((internalValue ?? 0) + amount).toString());

		// updateValue(((internalValue ?? 0) + newAmount).toString());
	};
	const isFractionalIncrementSku = () =>
		fractionalIncrementQtyLimit !== undefined &&
		fractionalIncrementQtyLimit &&
		fractionalIncrementQtyLimit > 0 &&
		!Number.isInteger(fractionalIncrementQtyLimit)
			? true
			: false;

	const stepValue = isFractionalIncrementSku() ? 1 : min;

	const inputRef = useRef<HTMLInputElement>();

	const onBlurHandler = (e: FocusEvent<HTMLInputElement>) => {
		if (disallowEmptyOnBlur && e.target.value.trim() === '') {
			updateValue((min ?? fieldValue ?? '').toString());
		}
	};

	/**
	 * Fire change event if real values have changed
	 */
	useEffect(() => {
		if (!onChange || externalUpdate === internalValue) {
			return;
		}
		onChange(internalValue);
		setExternalUpdate(internalValue);
		if (inputRef.current && customValidator) {
			customValidator(internalValue)
				? inputRef.current.setCustomValidity('')
				: inputRef.current.setCustomValidity('error');
			// We currently aren't showing built-in form validation message.
			// This just needs to be a non-empty string for useForm() to trigger error state.
		}
	}, [onChange, internalValue, externalUpdate, customValidator]);

	/**
	 * Update based on received input changes.
	 */
	useEffect(() => {
		const updatedValue = convertMaybeStringToInt({
			input: value,
			decimalSeparator,
			thousandSeparator,
		});
		setInternalValue(updatedValue);
		setExternalUpdate(updatedValue);
		setFieldValue(
			formatNumberValue({
				value,
				precision,
				decimalSeparator,
				thousandSeparator,
				min,
				max,
				fractionalIncrementQtyLimit,
			}) ?? null
		);
	}, [
		decimalSeparator,
		max,
		min,
		precision,
		thousandSeparator,
		value,
		fractionalIncrementQtyLimit,
	]);

	// Customized to use min value for sepcialOrder sku qty steps.

	return loading ? (
		<ProgressIndicator />
	) : (
		<TextField
			{...props}
			value={fieldValue ?? ''}
			type="text"
			sx={combineSX([numberInputContainerSX(showControls), sx])}
			onChange={changeHandler}
			inputProps={{
				'aria-label': common.quantity.t({ n: fieldValue ?? '' }),
				maxLength: maxLength ?? null,
			}}
			inputRef={inputRef}
			onBlur={onBlurHandler}
			disabled={disableTextInput}
			InputProps={{
				startAdornment: showControls ? (
					<Button
						disabled={disabled}
						aria-label={common.decrement.t()}
						onClick={step(-stepValue)}
						sx={numberInputControlsSX}
					>
						<RemoveIcon />
					</Button>
				) : prefix ? (
					<InputAdornment position="start" sx={numberInputInputAdornmentSX}>
						{prefix}
					</InputAdornment>
				) : null,
				endAdornment: showControls ? (
					<Button
						disabled={disabled}
						onClick={step(stepValue)}
						sx={numberInputControlsSX}
						aria-label={common.increment.t()}
					>
						<AddIcon />
					</Button>
				) : postfix ? (
					<InputAdornment position="end" sx={numberInputInputAdornmentSX}>
						{postfix}
					</InputAdornment>
				) : null,
			}}
		/>
	);
};
